import {Table, TableBody, TableContainer, TableRow} from '@mui/material';
import classNames from 'classnames';
import {useEffect, useRef} from 'react';
import {NewTableProps, Row, RowEditType} from './types';
import TableSimpleRow from './TableSimpleRow';
import TableEditRow from './TableEditRow';
import TableHead from './TableHead';
import styles from './NewTable.module.scss';
import TablePagination from './TablePagination';
import NoDataRow from './NoDataRow';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {TableDragDropContext} from './TableDragDropContext';
import {DraggableProvided, DroppableProvided} from 'react-beautiful-dnd';
import {TableDraggableRow} from './TableDragabbleRow';
import {useTabs} from '@pages/GroupedPages/useTabs';
import {NestedTabSelect} from '@ui/NestedTabSelect/NestedTabSelect';
import {useTableActions} from 'utils/hooks/useTableActions';
import {FiltersRow} from './components/FiltersRow';

dayjs.extend(utc);
dayjs.extend(timezone);

const NewTable = <T extends Row>({
    tableConfig,
    columns,
    rows,
    addEntityHandle,
    editEntityHandle,
    deleteEntityHandle,
    onUpdate,
    sortConfig,
    setSortConfig,
    totalElements = 0,
    setRowsPerPage,
    setPage,
    page,
    rowsPerPage,
    tableName,
    initialRow,
    requiredConfig = [],
    onChangeOrder,
    hoveredElement,
    EditRowComponent,
    EditDialog,
    dynamicValidationConfig,
    setUpdateDictionaries,
    confirmDeletingDescription,
    onDeleteClick,
    hideNestedTab = false,
    setFilterConfig,
    filterConfig,
    customEditClick,
    customAddClick,
}: NewTableProps<T>) => {
    const {currentNestedTab} = useTabs();

    const {withPagination, editType = RowEditType.INLINE} = tableConfig;
    const tableRef = useRef<HTMLDivElement | null>(null);

    const {
        selected,
        setSelected,
        editElement,
        setEditElement,
        createMode,
        setCreateMode,
        handleSelectAllClick,
        handleSelect,
        handleAddRow,
        isSelected,
        onEditClick,
        onDelete,
        onEdit,
        onCreate,
        handleRequestSort,
        handleSetRowsPerPage,
    } = useTableActions<T>({
        rows,
        addEntityHandle,
        editEntityHandle,
        deleteEntityHandle,
        onUpdate,
        setSortConfig,
        sortConfig,
        setUpdateDictionaries,
        tableName,
        setRowsPerPage,
        customEditClick,
        customAddClick,
    });

    const setDialogOpen = () => {
        if (createMode) {
            setCreateMode(false);
        } else {
            setEditElement(null);
        }
    };

    useEffect(() => {
        setSelected([]);
        setEditElement(null);
        setCreateMode(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, tableName]);

    useEffect(() => {
        setPage && setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableName]);

    const FinalEditRowComponent = EditRowComponent ?? TableEditRow;

    return (
        <>
            <TableDragDropContext
                rows={rows}
                onChangeOrder={onChangeOrder}
            >
                {(droppableProvided: DroppableProvided) => {
                    return (
                        <>
                            {!hideNestedTab && currentNestedTab && (
                                <NestedTabSelect nestedTab={currentNestedTab} />
                            )}
                            <TableContainer
                                ref={tableRef}
                                className={classNames(styles.tableContainer, {
                                    [styles.tableContainerAfterTab]:
                                        !hideNestedTab && currentNestedTab,
                                })}
                            >
                                <Table>
                                    <TableHead
                                        columns={columns}
                                        selected={selected}
                                        sortConfig={sortConfig}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                        totalElements={totalElements}
                                        tableConfig={tableConfig}
                                        disableAction={!!editElement || createMode}
                                        onDelete={onDelete}
                                        onAdd={handleAddRow}
                                        onDeleteClick={onDeleteClick}
                                        confirmDeletingDescription={confirmDeletingDescription}
                                        filterConfig={filterConfig}
                                        setFilterConfig={setFilterConfig}
                                    />
                                    <TableBody
                                        ref={droppableProvided.innerRef}
                                        {...droppableProvided.droppableProps}
                                    >
                                        {filterConfig && !!Object.keys(filterConfig).length && (
                                            <FiltersRow
                                                totalElements={totalElements}
                                                setFilterConfig={setFilterConfig}
                                            />
                                        )}
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);

                                            return (
                                                <TableDraggableRow
                                                    row={row}
                                                    index={index}
                                                    key={row.id + '_' + index}
                                                >
                                                    {(draggableProvided: DraggableProvided) => (
                                                        <TableRow
                                                            sx={{
                                                                minHeight: '40px',
                                                                backgroundColor:
                                                                    hoveredElement === row.id
                                                                        ? 'var(--mui-palette-action-hover)'
                                                                        : '',
                                                                '& .MuiTableCell-root': {
                                                                    backgroundColor:
                                                                        hoveredElement === row.id
                                                                            ? 'var(--mui-palette-action-hover)'
                                                                            : '',
                                                                },
                                                            }}
                                                            ref={draggableProvided?.innerRef}
                                                            {...draggableProvided?.draggableProps}
                                                        >
                                                            {editElement?.id !== row.id ||
                                                            editType === RowEditType.POPUP ? (
                                                                <TableSimpleRow
                                                                    columns={columns}
                                                                    row={row}
                                                                    tableConfig={tableConfig}
                                                                    onEditClick={onEditClick}
                                                                    handleSelect={handleSelect}
                                                                    isItemSelected={isItemSelected}
                                                                    disableAction={
                                                                        (!!editElement &&
                                                                            editElement?.id !==
                                                                                row.id) ||
                                                                        createMode
                                                                    }
                                                                    onDelete={onDelete}
                                                                    onDeleteClick={onDeleteClick}
                                                                    draggableProvided={
                                                                        draggableProvided
                                                                    }
                                                                    confirmDeletingDescription={
                                                                        confirmDeletingDescription
                                                                    }
                                                                />
                                                            ) : (
                                                                <FinalEditRowComponent
                                                                    columns={columns}
                                                                    row={row}
                                                                    onClose={() =>
                                                                        setEditElement(null)
                                                                    }
                                                                    tableConfig={tableConfig}
                                                                    isItemSelected={isItemSelected}
                                                                    onEdit={onEdit}
                                                                    onCreate={onCreate}
                                                                    requiredConfig={requiredConfig}
                                                                    draggableProvided={
                                                                        draggableProvided
                                                                    }
                                                                    dynamicValidationConfig={
                                                                        dynamicValidationConfig
                                                                    }
                                                                />
                                                            )}
                                                        </TableRow>
                                                    )}
                                                </TableDraggableRow>
                                            );
                                        })}
                                        {droppableProvided.placeholder}
                                        {createMode && editType !== RowEditType.POPUP && (
                                            <TableRow sx={{minHeight: '40px'}}>
                                                <FinalEditRowComponent
                                                    requiredConfig={requiredConfig}
                                                    columns={columns}
                                                    row={initialRow || {}}
                                                    onClose={() => setCreateMode(false)}
                                                    tableConfig={tableConfig}
                                                    isItemSelected={false}
                                                    onEdit={onEdit}
                                                    onCreate={onCreate}
                                                    dynamicValidationConfig={
                                                        dynamicValidationConfig
                                                    }
                                                />
                                            </TableRow>
                                        )}
                                        {rows.length === 0 &&
                                            (!createMode || editType === RowEditType.POPUP) && (
                                                <NoDataRow />
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {withPagination && (
                                <TablePagination
                                    totalElements={totalElements}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    setPage={setPage}
                                    setRowsPerPage={handleSetRowsPerPage}
                                />
                            )}
                            {editType === RowEditType.POPUP &&
                                (editElement || createMode) &&
                                EditDialog && (
                                    <EditDialog
                                        data={editElement}
                                        setOpen={setDialogOpen}
                                        refetchTableData={onUpdate}
                                    />
                                )}
                        </>
                    );
                }}
            </TableDragDropContext>
        </>
    );
};

export default NewTable;
