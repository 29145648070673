import {WellTreeFieldItem, WellTreePadItem, WellTreeType} from '@domain/well/types';
import {Checkbox, Stack} from '@mui/material';
import {useCallback} from 'react';
import {SimpleTreeView, TreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {hasCommonElement} from 'utils';

type PadsTreeMultipleProps = {
    selectedPads: Set<number>;
    toggleManyPads: (ids: number[], selected: boolean) => void;
    tree: WellTreeType;
};

export const PadsTreeMultiple = ({selectedPads, toggleManyPads, tree}: PadsTreeMultipleProps) => {
    const allFieldChildrenSelected = (field: WellTreeFieldItem) => {
        if (!field.padsId) {
            return false;
        }
        return field.padsId.every((id) => selectedPads.has(id));
    };

    const isFieldPartiallySelected = (field: WellTreeFieldItem) => {
        if (!field.padsId) {
            return false;
        }
        return hasCommonElement(selectedPads, field.padsId) && !allFieldChildrenSelected(field);
    };

    const handleFieldCheckbox = (field: WellTreeFieldItem, checked: boolean) => {
        if (field.padsId) {
            toggleManyPads(field.padsId, !checked);
        }
    };

    const onSelectedItemsChange = useCallback(
        (_: unknown, itemIds: string | null) => {
            if (itemIds && itemIds.startsWith('pad_')) {
                const id = Number(itemIds.substring(4));
                toggleManyPads([id], !selectedPads.has(id));
            }
        },
        [selectedPads, toggleManyPads],
    );

    const handlePadCheckbox = (pad: WellTreePadItem, checked: boolean) => {
        toggleManyPads([Number(pad.id)], !checked);
    };

    return (
        <SimpleTreeView
            onSelectedItemsChange={onSelectedItemsChange}
            aria-label='Дерево месторождений'
            slots={{
                collapseIcon: ExpandMoreIcon,
                expandIcon: ChevronRightIcon,
            }}
            sx={{
                '& .MuiTreeItem-content': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    borderRadius: 0,
                    '&:hover': {
                        borderRadius: 0,
                    },
                },
            }}
        >
            {tree.map((field) => (
                <TreeItem
                    itemId={field.name}
                    label={
                        <Stack
                            direction='row'
                            alignItems='center'
                        >
                            <Checkbox
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleFieldCheckbox(
                                        field,
                                        allFieldChildrenSelected(field) ?? false,
                                    );
                                }}
                                sx={{
                                    marginRight: '4px',
                                    padding: 0,
                                }}
                                size='small'
                                checked={allFieldChildrenSelected(field) ?? false}
                                indeterminate={isFieldPartiallySelected(field)}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                            {field.name}
                        </Stack>
                    }
                    key={field.name}
                >
                    {field.pads.map((pad) => (
                        <TreeItem
                            itemId={`pad_${pad.id}`}
                            label={
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                >
                                    <Checkbox
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handlePadCheckbox(pad, false);
                                        }}
                                        sx={{
                                            marginRight: '4px',
                                            padding: 0,
                                        }}
                                        size='small'
                                        checked={selectedPads.has(Number(pad.id))}
                                        inputProps={{'aria-label': 'controlled'}}
                                    />
                                    {pad.name}
                                </Stack>
                            }
                        ></TreeItem>
                    ))}
                </TreeItem>
            ))}
        </SimpleTreeView>
    );
};
