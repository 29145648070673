import {OperationStatus, Roles} from './types';

export const rolesLables = {
    [Roles.OPERATOR]: 'Оператор',
    [Roles.ADMINISTRATOR]: 'Администратор',
    [Roles.COORDINATOR]: 'Координатор',
    [Roles.INVEST]: 'Мониторинг',
    [Roles.SUPPORT]: 'Тех. поддержка',
};

export const rolesColors = {
    [Roles.OPERATOR]: 'var(--mui-palette-primary-main)',
    [Roles.ADMINISTRATOR]: 'rgba(149, 117, 205, 1)',
    [Roles.COORDINATOR]: 'rgba(77, 182, 172, 1)',
    [Roles.INVEST]: 'var(--mui-palette-warning-dark)',
    [Roles.SUPPORT]: 'var(--mui-palette-success-main)',
};

export const operationStatusLabels = {
    [OperationStatus.SUCCESS]: 'Выполнен',
    [OperationStatus.PROCESSING]: 'Обрабатывается',
    [OperationStatus.ERROR]: 'Ошибка',
};

export const operationStatusColors = {
    [OperationStatus.SUCCESS]: 'text.primary',
    [OperationStatus.PROCESSING]: ' var(--mui-palette-text-disabled)',
    [OperationStatus.ERROR]: 'var(--mui-palette-error-main)',
};
