export enum Roles {
    OPERATOR = 'OPERATOR',
    ADMINISTRATOR = 'ADMINISTRATOR',
    COORDINATOR = 'COORDINATOR',
    INVEST = 'INVEST',
    SUPPORT = 'SUPPORT',
}

export enum OperationStatus {
    SUCCESS = 'SUCCESS',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
}

export type AccountData = {
    id?: string;
    username: string;
    role: Roles | null;
    permittedPadIds: number[] | null; //для ролей не OPERATOR это поле null
    organization: string;
    phoneNumber: string | null;
    email: string | null;
    operationStatus?: OperationStatus | null;
};

export type AccountDataWithPassword = AccountData & {
    password: string | null;
    passwordConfirm: string | null;
};

export type OpenModal = {open: boolean; id?: string} | null;
