import {ReactElement} from 'react';
import {Row} from './types';
import {DragDropContext, Droppable, DropResult, DroppableProvided} from 'react-beautiful-dnd';
import {reorderItems} from 'utils/functions/reorderItems';

type TableDragDropContextProps = {
    rows: Row[];
    onChangeOrder?: (orders: Array<string | number>) => Promise<any>;
    children: (droppableProvided: DroppableProvided) => ReactElement;
};

export const TableDragDropContext = ({
    rows,
    onChangeOrder,
    children,
}: TableDragDropContextProps) => {
    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorderItems(rows, result);

        if (items) {
            onChangeOrder?.(items);
        }
    };
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable
                droppableId='droppable'
                direction='vertical'
            >
                {(droppableProvided: DroppableProvided) => children(droppableProvided)}
            </Droppable>
        </DragDropContext>
    );
};
