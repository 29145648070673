import {useEffect, useMemo} from 'react';
import {ColumnData} from './types';
import {getOptions, getTableField} from './utils';
import {useSelector} from 'react-redux';
import {selectDictionaries} from 'slices';
import {useDictionary} from 'utils/hooks/useDictionary';
import styles from './NewTable.module.scss';
import {usePrevious} from '@core/utils/hooks';

type TableEditCellProps = {
    CustomComponent?: any;
    customProps?: Record<string, any>;
    column: ColumnData;
    values: Record<string, any>;
    setValue: (key: string, newValue: any) => void;
    requiredConfig?: string[];
    initialDictKeyValue?: any;
};

const TableEditCell = ({
    column,
    values,
    setValue,
    CustomComponent,
    customProps,
    requiredConfig = [],
    initialDictKeyValue,
}: TableEditCellProps) => {
    const dictionaries = useSelector(selectDictionaries);

    const currentValue = useMemo(() => values[column.id], [column.id, values]);

    // значение влияющего поля
    const dictKeyPropertyValue = useMemo(() => {
        return column?.dictKeyProperty ? values[column.dictKeyProperty] : null;
    }, [column?.dictKeyProperty, values]);
    const prevKeyPropertyValue = usePrevious(dictKeyPropertyValue);

    // инициируем запрос за данными для зависимого поля только при наличии значений в влияющем поле
    const needQuery = useMemo(() => {
        if (column?.keyTableAttr) {
            return true;
        }
        if (column.dictName && column?.dictKeyProperty && dictKeyPropertyValue) {
            return true;
        }
        return false;
    }, [column?.dictKeyProperty, column.dictName, column?.keyTableAttr, dictKeyPropertyValue]);

    useDictionary({
        code: column.dictName,
        key: column?.keyTableAttr ? undefined : values[column?.dictKeyProperty!],
        need: needQuery,
    });
    const dictionary = column.dictName && dictionaries[column.dictName];

    // Сбрасываем значение зависимого поля при ИЗМЕНЕНИИ значения влияющего поля (при условии, что словарь загружен для первого рендера)
    useEffect(() => {
        // Если есть keyTableAttr, то проверяем, является ли выбранное значение валидным. Если нет, то сбрасываем
        if (dictionary && column?.keyTableAttr) {
            const options = getOptions({dictionary, column, values});
            const hasValidOptions = options.includes(currentValue);
            if (hasValidOptions) {
                return;
            } else {
                setValue(column.id, null);
                return;
            }
        }
        if (
            prevKeyPropertyValue === dictKeyPropertyValue ||
            (prevKeyPropertyValue === undefined && initialDictKeyValue === dictKeyPropertyValue)
        ) {
            return;
        }
        if (dictionary && column?.dictKeyProperty) {
            setValue(column.id, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        column?.dictKeyProperty,
        column.id,
        column?.keyTableAttr,
        currentValue,
        dictKeyPropertyValue,
        dictionary,
        initialDictKeyValue,
        prevKeyPropertyValue,
        setValue,
    ]);

    // Сбрасываем значение зависимого поля при ОБНУЛЕНИИ значения влияющего поля
    useEffect(() => {
        if (column.dictName && column.dictKeyProperty && !dictKeyPropertyValue) {
            setValue(column.id, null);
        }
    }, [column.dictKeyProperty, column.dictName, column.id, dictKeyPropertyValue, setValue]);

    // Блокируем поле при обновлении значений (новый запрос) из-за изменений влияющего поля
    const disableField = useMemo(() => {
        if (column?.dictName && column?.dictKeyProperty && !column?.keyTableAttr) {
            return dictKeyPropertyValue ? !dictionaries[column.dictName]?.length : true;
        }
        return false;
    }, [
        column.dictName,
        column?.dictKeyProperty,
        column?.keyTableAttr,
        dictKeyPropertyValue,
        dictionaries,
    ]);

    const required = requiredConfig.some((key) => key === column.id);

    return (
        <div className={styles.editCell}>
            {getTableField({
                valueKey: column.id,
                values: values,
                setValue,
                column,
                disabled: disableField,
                dictionary: column?.dictName ? dictionaries?.[column.dictName] : [],
                CustomComponent,
                customProps,
                required,
            })}
        </div>
    );
};

export default TableEditCell;
