import {AccountsService} from '@services/Accounts/AccountsService';
import NewTable from '@ui/NewTable/NewTable';
import {useEffect} from 'react';
import {useTablePage} from 'utils/hooks/useTablePage';
import {AccountData, OpenModal, OperationStatus} from './types';
import {useAccountsTableData} from './useAccountsTableData';
import {formateAccountsColumns} from './utils';
import {setNotification} from 'slices/notifications/notificationsSlice';
import {useAppDispatch} from 'slices/hooks';

type AccountsTableProps = {
    setOpenModal: React.Dispatch<React.SetStateAction<OpenModal>>;
    forceRefetch: number;
};

export const AccountsTable = ({setOpenModal, forceRefetch}: AccountsTableProps) => {
    const service = AccountsService;
    const [pageStore, updatePageData] = useAccountsTableData();
    const dispatch = useAppDispatch();

    const schemaGetter = () => service.getAccountsTableSchema();

    const {
        page,
        rowsPerPage,
        sortConfig,
        schema,
        columns,
        tableConfig,
        requiredConfig,
        rows,
        ref,
        handleUpdate,
        setPage,
        setRowsPerPage,
        setSortConfig,
        setUpdateDictionaries,
        filterConfig,
        setFilterConfig,
    } = useTablePage({
        pageId: 'accounts',
        pageStore,
        service,
        updatePageData,
        schemaGetter,
    });

    const customAddClick = () => {
        setOpenModal({open: true});
    };

    const customEditClick = (data: AccountData) => {
        setOpenModal({open: true, id: data.id});
    };

    const deleteEntityHandle = (ids: number[]) => {
        return service.deleteData(ids);
    };

    useEffect(() => {
        if (!!schema) {
            handleUpdate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, schema, sortConfig, rowsPerPage, filterConfig, forceRefetch]);

    const formattedColumns = formateAccountsColumns(columns);

    useEffect(() => {
        if (rows) {
            if (rows.some((row) => row.operationStatus === OperationStatus.PROCESSING)) {
                dispatch(
                    setNotification({
                        title: 'Запрос обрабатывается. Обновите страницу, чтобы узнать статус запроса.',
                        text: '',
                        variant: 'warning',
                    }),
                );
            }
            if (rows.some((row) => row.operationStatus === OperationStatus.ERROR)) {
                dispatch(
                    setNotification({
                        title: 'При обработке запроса произошла ошибка. Попробуйте еще раз или обратитесь в тех.поддержку. ',
                        text: '',
                        variant: 'error',
                    }),
                );
            }
        }
    }, [dispatch, rows]);

    if (!schema?.properties) {
        return null;
    }
    return (
        <div className='page'>
            <div
                ref={ref}
                className='table-container'
            >
                <NewTable
                    tableName='accounts'
                    columns={formattedColumns}
                    tableConfig={tableConfig}
                    rows={rows}
                    filterConfig={filterConfig}
                    setFilterConfig={setFilterConfig}
                    customAddClick={customAddClick}
                    customEditClick={customEditClick}
                    deleteEntityHandle={deleteEntityHandle}
                    onUpdate={handleUpdate}
                    sortConfig={sortConfig}
                    setSortConfig={setSortConfig}
                    totalElements={pageStore?.total || 0}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                    requiredConfig={requiredConfig}
                    setUpdateDictionaries={setUpdateDictionaries}
                />
            </div>
        </div>
    );
};
