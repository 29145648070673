export const isNegative = (num: number) => {
    if (Math.sign(num) === -1) {
        return true;
    }

    return false;
};

export const formatNumber = (number: number | string) => {
    // Вставляет пробелы на каждые 000
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const changeNumberField = (
    value: string,
    onChange: (value: string) => void,
    pattern: RegExp = /^\d*$/,
) => {
    const trimValue = value.trim();
    if (!pattern.test(trimValue) && value !== '') return;

    onChange(trimValue);

    return trimValue
};
