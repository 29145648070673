import {useState} from 'react';
import {AccountsTable} from './AccountsTable';
import {OpenModal} from './types';
import {AccountModal} from './AccountModal/AccountModal';

export const Accounts = () => {
    const [openModal, setOpenModal] = useState<OpenModal>(null);
    const [forceRefetch, setForceRefetch] = useState(0);

    return (
        <div>
            <AccountsTable
                setOpenModal={setOpenModal}
                forceRefetch={forceRefetch}
            />
            {openModal && openModal.open && (
                <AccountModal
                    id={openModal?.id}
                    setOpenModal={setOpenModal}
                    setForceRefetch={setForceRefetch}
                />
            )}
        </div>
    );
};
