import styles from './NewTable.module.scss';
import {Checkbox, IconButton, TableCell} from '@mui/material';
import {AddOutlined} from '@mui/icons-material';
import {RowEditType, TableHeadProps} from './types';
import {ButtonWithConfirm} from 'components/ButtonWithConfirm/ButtonWithConfirm';
import cn from 'classnames';

type TableHeadActionsProps = Omit<TableHeadProps, 'columns'> &
    Pick<TableHeadProps, 'cellClassName'>;

export const TableHeadActions = ({
    tableConfig,
    disableAction,
    onSelectAllClick,
    onAdd,
    onDelete,
    onDeleteClick,
    confirmDeletingDescription,
    selected = [],
    rowCount = 0,
    totalElements = 0,
    cellClassName,
}: TableHeadActionsProps) => {
    const {
        canDelete,
        canCreate,
        elementsMaxLimit,
        withOrders,
        canEdit,
        editType = RowEditType.INLINE,
        emptyCell = false,
    } = tableConfig;

    const haveDelete = canDelete && editType === RowEditType.INLINE;

    return (
        <>
            {(canDelete || canCreate || canEdit || emptyCell) && (
                <TableCell
                    padding='none'
                    className={cn(styles.sticky, cellClassName)}
                    style={{zIndex: 4, maxWidth: '130px'}}
                >
                    {editType === RowEditType.POPUP && canEdit && (
                        <span style={{width: '35px', display: 'inline-block'}} />
                    )}
                    {editType === RowEditType.POPUP && canDelete && (
                        <span style={{width: '35px', display: 'inline-block'}} />
                    )}
                    {emptyCell && <span style={{width: '35px', display: 'inline-block'}} />}
                    {withOrders && <span style={{width: '25px', display: 'inline-block'}} />}
                    {haveDelete && (
                        <span>
                            <Checkbox
                                disabled={disableAction || rowCount === 0}
                                color='primary'
                                indeterminate={selected.length > 0 && selected.length < rowCount}
                                checked={rowCount > 0 && selected.length === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all desserts',
                                }}
                            />
                        </span>
                    )}
                    {canCreate && (
                        <span>
                            <IconButton
                                onClick={() => {
                                    onAdd && onAdd();
                                }}
                                disabled={
                                    (elementsMaxLimit
                                        ? totalElements >= elementsMaxLimit
                                        : false) || disableAction
                                }
                                color='inherit'
                            >
                                <AddOutlined />
                            </IconButton>
                        </span>
                    )}
                    {haveDelete && (
                        <span>
                            <ButtonWithConfirm
                                fontSize='medium'
                                onConfirm={() => onDelete && onDelete(selected)}
                                onClick={async () => {
                                    if (onDeleteClick) {
                                        await onDeleteClick(selected.map((i) => String(i)));
                                    }
                                }}
                                disabled={selected.length === 0 || disableAction}
                                description={confirmDeletingDescription}
                            />
                        </span>
                    )}
                </TableCell>
            )}
        </>
    );
};
