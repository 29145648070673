import {Checkbox, IconButton, TableCell} from '@mui/material';
import {EditOutlined} from '@mui/icons-material';
import styles from './NewTable.module.scss';
import {ButtonWithConfirm} from 'components/ButtonWithConfirm/ButtonWithConfirm';
import {RowEditType, Row, TableSimpleRowProps} from 'components/NewTable/types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {LibraryBooksOutlined} from '@mui/icons-material';

type TableSimpleRowActionsProps<T extends Row> = Omit<TableSimpleRowProps<T>, 'columns'>;

export const TableSimpleRowActions = <T extends Row>({
    tableConfig,
    disableAction,
    isItemSelected,
    handleSelect,
    onEditClick,
    onDelete,
    onDeleteClick,
    row,
    draggableProvided,
    confirmDeletingDescription,
}: TableSimpleRowActionsProps<T>) => {
    const {canDelete, canEdit, withOrders, editType = RowEditType.INLINE} = tableConfig;
    const editIcon =
        editType === RowEditType.POPUP ? (
            <LibraryBooksOutlined color='primary' />
        ) : (
            <EditOutlined />
        );

    const haveDelete = canDelete && editType === RowEditType.INLINE;

    return (
        <>
            {(haveDelete || canEdit) && (
                <TableCell
                    padding='none'
                    className={styles.sticky}
                >
                    <span
                        {...draggableProvided?.dragHandleProps}
                        style={{
                            display: withOrders ? 'initial' : 'none',
                            verticalAlign: 'middle',
                        }}
                    >
                        <DragIndicatorIcon color={disableAction ? 'disabled' : 'inherit'} />
                    </span>

                    {haveDelete && (
                        <span>
                            <Checkbox
                                color='primary'
                                disabled={disableAction}
                                checked={isItemSelected}
                                onChange={(event) => handleSelect(event, row.id)}
                            />
                        </span>
                    )}
                    {canEdit && (
                        <span>
                            <IconButton
                                onClick={() => onEditClick(row)}
                                disabled={disableAction}
                                color='inherit'
                            >
                                {editIcon}
                            </IconButton>
                        </span>
                    )}
                    {haveDelete && (
                        <span>
                            <ButtonWithConfirm
                                onClick={async () => {
                                    if (onDeleteClick) {
                                        await onDeleteClick([row.id]);
                                    }
                                }}
                                onConfirm={() => onDelete([row.id])}
                                disabled={disableAction}
                                fontSize='medium'
                                description={confirmDeletingDescription}
                            />
                        </span>
                    )}
                </TableCell>
            )}
        </>
    );
};
