import {Badge, Box, TableCell} from '@mui/material';
import {ArrowDownward, ArrowUpward, FilterList} from '@mui/icons-material';
import {ColumnData, ColumnTypes, FilterConfig, SortDirection, TableHeadProps} from './types';
import styles from './NewTable.module.scss';
import cn from 'classnames';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {useState} from 'react';
import {HeaderFilter} from './components/HeaderFilter';

type TableHeadCellProps = Pick<
    TableHeadProps,
    'sortConfig' | 'onRequestSort' | 'disableAction' | 'cellClassName'
> & {
    isSortable?: boolean;
    withOrders?: boolean;
    column: ColumnData;
    minHeight?: string;
    isLastCell: boolean;
    filterConfig?: FilterConfig;
    setFilterConfig?: React.Dispatch<React.SetStateAction<FilterConfig>>;
};

export const TableHeadCell = ({
    column,
    sortConfig,
    onRequestSort,
    isSortable = false,
    disableAction = false,
    withOrders = false,
    minHeight = '40px',
    cellClassName,
    isLastCell,
    setFilterConfig,
    filterConfig,
}: TableHeadCellProps) => {
    const [showFilter, setShowFilter] = useState(false);
    const filterDictName = column?.filterDictName;

    const getSortDirection = (columnId: string) => {
        if (!sortConfig) {
            return null;
        }
        const sortDirection = sortConfig[columnId];
        if (!sortDirection) {
            return null;
        }
        if (sortDirection === SortDirection.ASC) {
            return (
                <span className={styles.sortIcon}>
                    <ArrowDownward fontSize='inherit' />
                </span>
            );
        }
        if (sortDirection === SortDirection.DESC) {
            return (
                <span className={styles.sortIcon}>
                    <ArrowUpward fontSize='inherit' />
                </span>
            );
        }
    };

    const columnTitle = column.type === ColumnTypes.OBJECT ? '' : column.title;

    const onFilterClick: React.MouseEventHandler<any> = (e) => {
        e.stopPropagation();
        setShowFilter(true);
    };

    const currentFilter = filterConfig ? filterConfig[column.id] : null;

    const handleChangeFilterValue = (value: string[]) => {
        setShowFilter(false);
        if (setFilterConfig) {
            if (!value || value.length === 0) {
                setFilterConfig((prev) => {
                    const newConfig = {...prev};
                    delete newConfig[column.id];
                    return newConfig;
                });
                return;
            }
            setFilterConfig((prev) => ({...prev, [column.id]: value}));
        }
    };

    const canSort = isSortable && !withOrders && !column?.withoutSort && !disableAction;

    return (
        <LightTooltip
            title={column?.description || ''}
            offset={[20, 0]}
            enterDelay={500}
        >
            <TableCell
                sx={{
                    minWidth: column?.width || '100px',
                    maxWidth: column?.width,
                    width: isLastCell ? '100%' : column?.width || '100px',
                    padding: '0 6px',
                    cursor: canSort ? 'pointer' : 'default',
                }}
                className={cn(styles.stickyHeader, cellClassName)}
            >
                {showFilter ? (
                    <HeaderFilter
                        column={column}
                        handleChangeFilterValue={handleChangeFilterValue}
                        selectedOptions={currentFilter || []}
                        setShowFilter={setShowFilter}
                    />
                ) : (
                    <div
                        className={styles.tableHeadCell}
                        style={
                            column.type === ColumnTypes.OBJECT
                                ? {alignItems: 'center', minHeight}
                                : {minHeight}
                        }
                        onClick={() => (canSort ? onRequestSort && onRequestSort(column.id) : null)}
                    >
                        <span>{columnTitle}</span>
                        <div className={styles.tableHeadCellActions}>
                            {isSortable && getSortDirection(column.id)}
                            {filterDictName && (
                                <Box
                                    onClick={onFilterClick}
                                    component='div'
                                    sx={{
                                        padding: '4px 10px 4px 8px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                            backgroundColor: 'var(--mui-palette-action-hover)',
                                        },
                                    }}
                                >
                                    <Badge
                                        badgeContent={currentFilter?.length || 0}
                                        color='primary'
                                        sx={{
                                            height: '100%',
                                            '& .MuiBadge-badge': {
                                                minWidth: '14px',
                                                height: '14px',
                                                padding: '0',
                                                color: 'var(--mui-palette-info-contrastText)',
                                                fontSize: '10px',
                                            },
                                        }}
                                    >
                                        <span className={styles.sortIcon}>
                                            <FilterList fontSize='inherit' />
                                        </span>
                                    </Badge>
                                </Box>
                            )}
                        </div>
                    </div>
                )}
            </TableCell>
        </LightTooltip>
    );
};
